import gql from '../../../../_snowpack/pkg/graphql-tag.js';
import { tenantBasicInfoFragment } from '../../../queries/get-me-and-tenants.js';
export const COMPLETE_SIGNUP = gql`
  mutation COMPLETE_SIGNUP(
    $companyName: String!
    $companyIdentifier: String!
    $tocReadAt: DateTime
    $marketingEmailConsentedAt: DateTime
    $firstName: String
    $lastName: String
    $meta: [KeyValuePairInput!]!
  ) {
    me {
      update(
        input: {
          companyName: $companyName
          tocReadAt: $tocReadAt
          marketingEmailConsentedAt: $marketingEmailConsentedAt
          firstName: $firstName
          lastName: $lastName
        }
      ) {
        id
      }
    }

    tenant {
      create(input: { name: $companyName, identifier: $companyIdentifier, meta: $meta }) {
        ...tenantBasicInfo
      }
    }
  }

  ${tenantBasicInfoFragment}
`;
export const SUGGEST_IDENTIFIER = gql`
  query SUGGEST_IDENTIFIER($desired: String!) {
    tenant {
      suggestIdentifier(desired: $desired) {
        suggestion
      }
    }
  }
`;