import styled from '../../../../_snowpack/pkg/styled-components.js';
export const Overlay = styled.div.withConfig({
  displayName: "styles__Overlay",
  componentId: "sc-1a5r4ua-0"
})(["position:absolute;top:0;right:0;bottom:0;left:0;z-index:1;background-color:rgba(0,0,0,0.015);backdrop-filter:blur(5px);@supports not ((-webkit-backdrop-filter:none) or (backdrop-filter:none)){background-color:rgba(255,255,255,0.8);}"]);
export const Wrapper = styled.div.withConfig({
  displayName: "styles__Wrapper",
  componentId: "sc-1a5r4ua-1"
})(["position:fixed;top:0;bottom:0;left:50px;background:#fff;z-index:2;padding:81px 34px 81px 65px;max-width:50ch;box-shadow:0px 2px 4px rgba(130,138,144,0.15);a{text-decoration-color:#c2e5e1;text-underline-offset:5px;text-decoration-thickness:2px;}footer{font-size:0.75rem;font-family:Roboto;line-height:1.5;position:absolute;bottom:1rem;right:34px;max-width:240px;p{margin:0;color:hsl(207.7,5.8%,43.7%);}}"]);
export const Title = styled.h1.withConfig({
  displayName: "styles__Title",
  componentId: "sc-1a5r4ua-2"
})(["font-family:Roboto Slab;font-style:normal;font-weight:bold;font-size:26px;line-height:96.19%;color:#4d525b;"]);
export const Heading = styled.h3.withConfig({
  displayName: "styles__Heading",
  componentId: "sc-1a5r4ua-3"
})(["font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:132.7%;color:#4d525b;"]);
export const Divider = styled.div.withConfig({
  displayName: "styles__Divider",
  componentId: "sc-1a5r4ua-4"
})(["width:100%;height:1px;background-color:#e0e0e0;margin:2em 0;"]);
export const Form = styled.form.withConfig({
  displayName: "styles__Form",
  componentId: "sc-1a5r4ua-5"
})(["display:flex;flex-direction:column;"]);
export const TextField = styled.label.withConfig({
  displayName: "styles__TextField",
  componentId: "sc-1a5r4ua-6"
})(["font-family:Roboto;font-style:normal;font-weight:500;font-size:12px;line-height:132.7%;color:#828a90;margin-bottom:2em;> input{display:block;width:100%;background:#ffffff;border:1px solid #dfdfdf;box-sizing:border-box;box-shadow:0px 2px 4px rgba(130,138,144,0.15);border-radius:4px;padding:1em 2em;margin-top:0.5em;&::placeholder{opacity:0.4;font-style:italic;}}"]);
export const Checkbox = styled.label.withConfig({
  displayName: "styles__Checkbox",
  componentId: "sc-1a5r4ua-7"
})(["font-family:Roboto;font-style:normal;font-weight:normal;font-size:14px;line-height:149.7%;color:#4d525b;position:relative;cursor:pointer;margin-bottom:0.5em;> input{opacity:0;}> input:focus + span.checkmark{outline:3px solid #4d90fe;outline-offset:-2px;-moz-outline-color:blue;}> input:checked + span.checkmark{background-color:#8fddca;}> span.checkmark{position:absolute;left:0;background-color:#fff;border-radius:5px;border:1px solid #979797;box-shadow:0 2px 3px rgba(0,0,0,0.0756392);box-sizing:border-box;display:inline-block;height:16px;width:16px;margin-right:1em;}"]);
export const SubmitButton = styled.button.withConfig({
  displayName: "styles__SubmitButton",
  componentId: "sc-1a5r4ua-8"
})(["background:#c2e5e1;border-radius:64px;border:none;align-self:flex-end;padding:1em 2em;font-family:Roboto;font-style:normal;font-weight:bold;font-size:14px;line-height:132.7%;color:#4d525b;margin-top:5rem;cursor:pointer;"]);
export const Illustration = styled.img.withConfig({
  displayName: "styles__Illustration",
  componentId: "sc-1a5r4ua-9"
})(["position:absolute;bottom:0;max-height:180px;"]);