import styled from '../../_snowpack/pkg/styled-components.js';
export const common = {
  dottedBorder: `2px dashed rgba(0,0,0,0.1)`,
  dottedBorderClear: '2px dashed #9095a8',
  dottedBorderThin: '0.5px dashed #9095a8',
  componentShadow: '0px 2px 4px rgba(211,207,244,0.2)'
};
export const Gutter = styled.div.withConfig({
  displayName: "common__Gutter",
  componentId: "sc-11orl7h-0"
})(["display:grid;background-color:#eeeff1;border-radius:8px;padding:20px 40px;width:100%;"]);
export const Label = styled.label.withConfig({
  displayName: "common__Label",
  componentId: "sc-11orl7h-1"
})(["color:", ";font-family:'Roboto',sans-serif;font-size:14px;display:block;margin:20px 0;font-style:normal;font-weight:500;"], ({
  theme
}) => theme.colors.label);
export const CurrencyChip = styled.span.withConfig({
  displayName: "common__CurrencyChip",
  componentId: "sc-11orl7h-2"
})(["color:", ";font-weight:500;padding:5px 10px;width:fit-content;border-radius:4px;font-size:14px;box-shadow:", ";border:1px solid #dfdfdf;"], ({
  theme
}) => theme.colors.label, ({
  theme
}) => theme.dropShadow.md);