import gql from '../../_snowpack/pkg/graphql-tag.js';
export const StockLocation = gql`
  fragment StockLocation on StockLocation {
    identifier
    name
    settings {
      minimum
      unlimited
    }
  }
`;
export const GET_STOCK_LOCATIONS = gql`
  query GET_STOCK_LOCATIONS($tenantId: ID!) {
    stockLocation {
      getMany(tenantId: $tenantId) {
        ...StockLocation
      }
    }
  }

  ${StockLocation}
`;